/* Global styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  /* Light teal background */
  color: #333;
  /* Dark text color for readability */
}

/* Styling for code elements */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background-color: #d0e7e7;
  /* Light teal background for code blocks */
  padding: 2px 4px;
  border-radius: 4px;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
  scroll-padding: 40rem;
}



/* Paragraphs */
p {
  line-height: 1.6;
  margin-bottom: 1em;
}

/* Links */
a {
  color: #00796b;
  /* Rich teal color for links */
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Buttons */
button {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: #009688;
  /* Vibrant teal background for buttons */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #a8c7c3;
  /* Rich teal on hover */
}

/* Containers */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Utility classes */
.text-center {
  text-align: center;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.p-1 {
  padding: 1em;
}

.p-2 {
  padding: 2em;
}

/* Additional styles */
ul {
  list-style-type: disc;
  margin-left: 20px;
}

ol {
  list-style-type: decimal;
  margin-left: 20px;
}

blockquote {
  border-left: 4px solid #004d40;
  /* Dark teal border for blockquotes */
  padding-left: 16px;
  color: #555;
  margin: 1em 0;
  background-color: #e0f2f1;
  /* Light teal background for blockquotes */
  padding: 1em;
}

hr {
  border: 0;
  height: 1px;
  background: #ddd;
  margin: 2em 0;
}

.sticky-search-bar {
  position: -webkit-sticky;
  /* For Safari */
  position: sticky;
  bottom: 0;
  width: fit-content;
  /* Adjust width to fit content */
  background-color: #ffffff;
  /* White background */
  box-shadow: 0 -2px 10px rgba(23, 79, 54, 0.1);
  /* Slight shadow for better visibility */
  z-index: 1000;
  /* Ensure it stays on top */
  margin: 0 auto;
  /* Center the element horizontally */
  border-radius: 50px;
  /* Rounded corners */

  /* Padding for a clean look */
  display: flex;
  /* Flexbox for alignment */
  align-items: center;
  /* Vertically center the content */
}